import React,{ useState, useEffect} from 'react'
import SelectDropdown from "../../components/SelectDropdown";
import MultiSelect from "../../components/Multiselect";
import StyledButton from '../../components/StyledButton';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { GET_AREA, GET_COUNTYCITY, GET_DAY_OPTION, GET_MAPDATA, GET_MONTH_OPTION, GET_ORDER_OPTION, GET_STORE_LIST, GET_TYPE, GET_VENDOR_LIST, SET_MAP_SEARCH } from "../../redux/actionTypes";
import { mapStateToProps } from "../../redux/reducers";
// import { CityData ,AreaWithin, vendorsName } from "../../utils/mock-data"
import Geocode from "react-geocode";
import { useCookies } from 'react-cookie';

import { sortName, vendorType, orderOptions, daysOfOperation, monthsOfOperation, AreaWithin } from "../../utils/constants";
import { GOOGLE_KEY } from '../../config';
import ModalComponent from '../common/components/modal';
import Modal from "../common/modal";
import { getNextDate, removeSpecialCharacter } from '../../utils/globalMethods';
const centerLocation = {
    lat: 42.2625932,
    lng: -71.8022934,
    address: "Massachusetts Center",
    city: "Boston",
    located: false
};
const defaultLocation = {
    lat: 42.3600825 ,
    lng: -71.0588801,
    address: "Boston, MA, USA",
    city: "Boston",
    located: false
};
Geocode.setApiKey(GOOGLE_KEY);
Geocode.enableDebug();

const SearchComponent = (props) => {
    const {setIsLoading} = props;
    const [language, setLanguage] = useState({});
    const [typeNames, setTypeNames] = useState(vendorType);
    const [orderOptionNames, setOrderOptionNames] = useState(orderOptions);
    const [monthNames, setMonthNames] = useState(monthsOfOperation);
    const [dayNames, setDayNames] = useState(daysOfOperation);
    const [selectedCity, setSelectedCity] = useState({id: "myLocation",label:props?.language["Use My Location"]});
    const [selectedArea, setSelectedArea] = useState({id:"5",label:"5 "+props?.language["miles"]})
    const [openToday, setOpenToday] = useState('No');
    const [cityList, setCityList] = useState([]);
    const [coordinates, setCoordinates] = useState(defaultLocation);  
    const [showLocationModal, setShowLocationModal] = useState(false);
    const [cookies, setCookie] = useCookies(['name']);
    const [modalShown, toggleModal] = useState(false);
    const [selectedVendors, setSelectedVendors] = useState({id:"all",label:props?.language["All Vendors"]});
    // const [areaList, setAreaList] = useState([]);
    const [vendorList, setVendorList] = useState([]);
    const [initialSearch, setInitialSearch] = useState(true);
    const [isClear, setIsClear] = useState(false);
    const [myLocation, setMyLocation] = useState(false);
    const [mycoordinates, setMycoordinates] = useState();
    const [areaWithinList, setAreaWithin] = useState();
    let selectedType = {
        "csaPickupSite": "Y",
        "farmStand": "Y",
        "farmersMarket": "Y",
        "farmersMarketBooth": "Y",
        "mobileMarket": "Y"
    };
    let selectedOrderOption = {
        "curbsidePickup": "Y",
        "delivery": "Y",
        "inPerson": "Y",
        "onlinePreselection": "Y",
        "phonePreselection": "Y",
        "otherType": "Y"
    };
    let selectedMonths = {
        "janOperation": "Y",
        "febOperation": "Y",
        "marOperation": "Y",
        "aprOperation": "Y",
        "mayOperation": "Y",
        "junOperation": "Y",
        "julOperation": "Y",
        "augOperation": "Y",
        "sepOperation": "Y",
        "octOperation": "Y",
        "novOperation": "Y",
        "decOperation": "Y"
    }; 
    let selectedDays ={
        "monOperation": "Y",
        "tueOperation": "Y",
        "wedOperation": "Y",
        "thuOperation": "Y",
        "friOperation": "Y",
        "satOperation": "Y",
        "sunOperation": "Y"
    }; 
    const [searchProperties, setSearchProperties] = useState({
        address: coordinates.address,
        city: "all",
        latitude: parseFloat(coordinates.lat),
        longitude: parseFloat(coordinates.lng),
        area: selectedArea,
        vendorType: "all",
        orderOptions: "all",
        months: "all",
        days: "all",
        vendor_name: "all",
        open_today: openToday,
        is_mylocation: false
      });
    const customStyles = {
        select: {
          padding: '19px 6px', 
          height: '30px',
          background:"#fff",
          border:"1px solid rgb(153 153 153 / 45%)",
          fontFamily: "Texta-book,Helvetica,Arial,sans-serif",
          color: "#464646",
          fontSize: "16px",
          marginTop:"4px"
        },
    };

    const fetchVendorList = (city,miles) => {
        props.getVendorList({
          "cityName": city.id === "all" ||   city.id === "myLocation"? "" : city.label,
          "miles": miles === "" ? "" : miles,
          "lat": parseFloat(coordinates.lat),
          "lon": parseFloat(coordinates.lng),
          "allCityFlag": city.id === "all" ? "Y" : "N"
        });
    }

    useEffect(() => {
        if(props.language){
          setLanguage(props.language)
          getGeocode(selectedCity,"fullWidth");
          props.getCityList();
        //   if (selectedCity.id === "myLocation") { 
        //     setSelectedCity({id:"myLocation", label:props?.language["Use My Location"], isActive: 1});
        //   } else 
            if (selectedCity.id === "all"){
                setSelectedCity({id:"all", label:props?.language["All Counties & Cities"], isActive: 1});
                setSelectedArea({id:"",label:props?.language["Statewide"]});
            } else {
                setSelectedArea({id:selectedArea.id,label: selectedArea.id == "1" ? selectedArea.label.replace("mile", props?.language["mile"]) : selectedArea.label.replace("miles", props?.language["miles"])});
            }
            if (selectedVendors.id === "all") {
                setSelectedVendors({id:"all",label:props?.language["All Vendors"]});
            }

            const data = [
                {
                    "id": "",
                    "label": props?.language["Statewide"],
                },
                {
                    "id": "1",
                    "label": "1 "+props?.language["mile"],
                },
                {
                    "id": "2",
                    "label": "2 "+props?.language["miles"],
                },
                {
                    "id": "5",
                    "label": "5 "+props?.language["miles"],
                },
                {
                    "id": "10",
                    "label": "10 "+props?.language["miles"],
                },
                {
                    "id": "15",
                    "label": "15 "+props?.language["miles"],
                },{
                    "id": "20",
                    "label": "20 "+props?.language["miles"],
                },
                {
                    "id": "25",
                    "label": "25 "+props?.language["miles"],
                },
                {
                    "id": "30",
                    "label": "30 "+props?.language["miles"]
                }
              ];
            setAreaWithin(data);
        }
    }, [props.language]);

    useEffect(() => {
      mapCityList(props.mapdata.city);
    //   mapAreaList(props.mapdata.area);
      mapVendorList(props.mapdata.vendor);
    },[props.mapdata.city,props.mapdata.vendor, props.language]);

    const getCurrentLocation=()=>{
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
                    Geocode.fromLatLng(position.coords.latitude, position.coords.longitude)
                    .then( (result) => {
                        if (result.status === "OK") {
                            const city = result?.results?.[0]?.address_components?.find(item=>item?.types?.indexOf("locality") !== -1);
                            setCoordinates((prevState) => ({
                                ...prevState,
                                address: result.results[0].formatted_address,
                                city: city?.long_name ? city?.long_name : "",
                                located:true
                            }));
                              setSearchProperties((prevState) => ({
                                  ...prevState,
                                  address: result.results[0].formatted_address,
                                  city: city?.long_name ? city?.long_name : "",
                                  is_mylocation: true
                              }));
                            setMyLocation(true);
                            props.getVendorList({
                                "cityName": city?.long_name ? city?.long_name : "",
                                "miles": selectedArea?.id,
                                "lat": parseFloat(position.coords.latitude),
                                "lon": parseFloat(position.coords.longitude),
                                "allCityFlag": "N"
                            });
                        }                        
                    });
                    setCoordinates((prevState) => ({
                        ...prevState,
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    }));
                    setSearchProperties((prevState) => ({
                        ...prevState,
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    }));
            },
            ()=>{
              setCoordinates((prevState) => ({
                ...prevState,
                located:false
              }));
          });
        }
      };
    const getGeocode = (city,modalSize) => {
        if (city.id ==="myLocation") {
            navigator.permissions.query({
                name: "geolocation"
            }).then(function(result) {
                if (result.state == "granted" || result.state == "prompt") {
                    getCurrentLocation();
                } else if (result.state == "denied") {
                //   setInitialStoreView(false);
                  setMyLocation(false);
                  setSelectedCity({id:"all", label:props?.language["All Counties & Cities"], isActive: 1});
                  setSelectedArea({id:"",label:props?.language["Statewide"]});
                  setSearchProperties((prevState) => ({
                    ...prevState,
                    area: {id:"",label:props.language["Statewide"]},
                    city: "all",
                    is_mylocation: false
                  }));
                  if(modalSize=="modal"){
                    setShowLocationModal(true);
                    props.showLocationModal(true);
                  }else if(modalSize=="fullWidth" && (!cookies.loc_permission_modal || cookies.loc_permission_modal == "false")){
                    toggleModal(true);
                  }                
                  // Initial vendor list api call
                if (initialSearch) {
                    props.getVendorList({
                        "cityName": "",
                        "miles": "",
                        "lat": parseFloat(coordinates.lat),
                        "lon": parseFloat(coordinates.lng),
                        "allCityFlag": "Y"
                    });
                    setInitialSearch(false);
                }
                }
            });            
        } else if (city.id === "all") {
            setCoordinates({ 
                lat: centerLocation.lat,
                lng: centerLocation.lng,
                address: centerLocation.address,
                city: centerLocation.city,
                located: true
            });
            setSearchProperties((prevState) => ({
                ...prevState,
                latitude: centerLocation.lat,
                longitude: centerLocation.lng,
                address: centerLocation.address,
                city: "all",
                area: {id:"",label:language["Statewide"]},
                is_mylocation: false
            }));
        } else {
            const address = city.label + ",MA";
            Geocode.fromAddress(address).then(
                (response) => {
                const { lat, lng } = response.results[0].geometry.location;
                setCoordinates({ 
                        lat: lat,
                        lng: lng,
                        address: response.results[0].formatted_address,
                        city: city.label,
                        located: true
                });
                setSearchProperties((prevState) => ({
                ...prevState,
                latitude: lat,
                longitude: lng,
                address: response.results[0].formatted_address,
                city: city.label,
                is_mylocation: false
            }));
                },
                (error) => {
                console.error(error);
                }
            );
        }  
    }; 
    
    const mapCityList =(city)=>{
        if (city && city.length) {
            let cityOption = city?.map(item => {
                return {
                    id: item.id,
                    label: item.cityName,
                    isActive: 1
                };
            });

            let defaultData = [{id:"myLocation", label:language["Use My Location"], isActive: 1, default: true},{id:"all", label:language["All Counties & Cities"], isActive: 1}];
            if (cityOption) {
                setCityList(defaultData.concat(sortName(cityOption, "label")));
            }
            // (selectedCity.id == "myLocation")  && setSelectedCity({id: "myLocation",label: language["Use My Location"]});
            // (selectedCity.id == "all")  && setSelectedCity({id:"all", label:language["All Counties & Cities"]});    
        }
    };
     
    const mapVendorList = (vendors) =>{ 
        if(vendors.length){
            let vendorOptions = vendors.map((item) => {
            return {
                id: item.vendorId,
                label: item.vendorName,
                isActive: 1
            };
            })
            const defaultData = [{id:"all",label:language["All Vendors"]}];
            vendorOptions.forEach(item =>{
                item.id = removeSpecialCharacter(item.id);
                item.label = removeSpecialCharacter(item.label);
            });
            setVendorList(defaultData.concat(sortName(vendorOptions,"label")));
            (selectedVendors.name == "all")  && setSelectedVendors({id:"all", label:language["All Vendors"]});
        }
    };

    const handleChange = (event) => {
        setOpenToday(event.target.value);
      };
    const handleTypeChange = (selectedTypes) => {
        setTypeNames(selectedTypes);      
    };
    const handleOrderOptionsChange = (selectedTypes) => {
        setOrderOptionNames(selectedTypes);      
    };
    const handleMonthsChange = (selectedTypes) => {
        setMonthNames(selectedTypes);      
    };
    const handleDayChange = (selectedTypes) => {
        setDayNames(selectedTypes);       
    };
    const handleCityChange = (v) => {
        console.log(v)
        let miles = "";
        setSearchProperties((prevState) => ({
          ...prevState,
          vendor_name: "all",
        }));
        setSelectedVendors({id:"all", label:language["All Vendors"]});
        getGeocode(v,"modal");
        if (v.id !== "all") {
            miles = "5";
            setSelectedArea({id:"5",label:"5 "+props?.language["miles"]});
            setSearchProperties((prevState) => ({
                ...prevState,
                area: {id:"5",label:"5 "+props?.language["miles"]},
                city: v.label
            }));
        } else {
          setSelectedArea({id:"",label:language["Statewide"]});
          setSearchProperties((prevState) => ({
              ...prevState,
              area: {id:"",label:language["Statewide"]},
              city: 'all'
          }));
        }
        setSelectedCity(v);
        fetchVendorList(v,miles);
    };
    const handleAreaChange = (newValue) => {
        setSelectedArea(newValue);
        setSearchProperties((prevState) => ({
            ...prevState,
            area: newValue,
        }));
        if(newValue.id == ""){
            setSelectedCity({id:"all", label:language["All Counties & Cities"]})
            setSearchProperties((prevState) => ({
                ...prevState,
                city: "all",
              }));
        }
    };
    const handleVendorChange = (v) => {
        setSelectedVendors(v);
        setSearchProperties((prevState) => ({
        ...prevState,
        vendor_name: v.name,
        }));
    };
    const handleClearSearch = () => {
        setSelectedVendors({id:"all", label:language["All Vendors"]});
        setIsClear(!isClear);
        setMyLocation(false);
        props.getVendorList({
            "cityName": "",
            "miles": "",
            "lat": parseFloat(coordinates.lat),
            "lon": parseFloat(coordinates.lng),
            "allCityFlag": "Y"
        });
        setSelectedCity({id:"all", label:props?.language["All Counties & Cities"], isActive: 1});
        setSelectedArea({id:"",label:props?.language["Statewide"]}); 
        setSearchProperties((prevState)=>({
            ...prevState,
            area: {id:"",label:props.language["Statewide"]},
            latitude: parseFloat(defaultLocation?.lat),
            longitude: parseFloat(defaultLocation?.lng),
            city: "all",
            address: defaultLocation?.address,
            vendorType: "all",
            orderOptions: "all",
            months: "all",
            days: "all",
            vendor_name: "all",
            is_mylocation: false
        }));
    }

    useEffect(()=>{
            setTypeNames(vendorType);
            setOrderOptionNames(orderOptions);
            setMonthNames(monthsOfOperation);
            setDayNames(daysOfOperation);
            setOpenToday("No");
            if (props.resultShown) {
                const payload = {
                    "cityName": myLocation ? mycoordinates?.city : "",
                    "miles": myLocation ? "5" : "",
                    "lat": myLocation ? parseFloat(mycoordinates?.lat) : defaultLocation.lat,
                    "lon": myLocation ? parseFloat(mycoordinates?.lng) : defaultLocation.lng,
                    "allCityFlag": myLocation ? "N" : "Y",
                    "vendorId": "",
                    "csaPickupSite": "Y",
                    "farmStand": "Y",
                    "farmersMarket": "Y",
                    "farmersMarketBooth": "Y",
                    "mobileMarket": "Y",
                    "monthsOperation": {
                        "janOperation": "Y",
                        "febOperation": "Y",
                        "marOperation": "Y",
                        "aprOperation": "Y",
                        "mayOperation": "Y",
                        "junOperation": "Y",
                        "julOperation": "Y",
                        "augOperation": "Y",
                        "sepOperation": "Y",
                        "octOperation": "Y",
                        "novOperation": "Y",
                        "decOperation": "Y"
                    },
                    "distributionOption": {
                        "delivery": "Y",
                        "curbsidePickup": "Y",
                        "onlinePreselection": "Y",
                        "phonePreselection": "Y",
                        "inPerson": "Y",
                        "otherType": "Y"
                    },
                    "monOperation": "Y",
                    "tueOperation": "Y",
                    "wedOperation": "Y",
                    "thuOperation": "Y",
                    "friOperation": "Y",
                    "satOperation": "Y",
                    "sunOperation": "Y",
                    "openToday": "N"
                };
                props.getMapStoresList(payload);
                props.searchProperties(searchProperties);
                props.updateBooleanValue(true);
                props.isClearSearch(true);
                setIsLoading(true);
            }
    },[isClear]);


    const handleButtonClick = () => {
        if (typeNames && typeNames.length) {
            const obj = typeNames.reduce((accumulator, value) => {
            return {...accumulator, [value.id]: 'Y'};
          }, {});
          selectedType = {
            "csaPickupSite": obj?.csaPickupSite ? "Y" : "N",
            "farmStand": obj?.farmStand ? "Y" : "N",
            "farmersMarket": obj?.farmersMarket ? "Y" : "N",
            "farmersMarketBooth": obj?.farmersMarketBooth ? "Y" : "N",
            "mobileMarket": obj?.mobileMarket ? "Y" : "N",
          };
        }

        if (monthNames && monthNames.length) {
            const obj = monthNames.reduce((accumulator, value) => {
            return {...accumulator, [value.id]: 'Y'};
          }, {});
          selectedMonths = {
            "janOperation": obj?.janOperation ? "Y":"N",
            "febOperation": obj?.febOperation ? "Y":"N",
            "marOperation": obj?.marOperation ? "Y":"N",
            "aprOperation": obj?.aprOperation ? "Y":"N",
            "mayOperation": obj?.mayOperation ? "Y":"N",
            "junOperation": obj?.junOperation ? "Y":"N",
            "julOperation": obj?.julOperation ? "Y":"N",
            "augOperation": obj?.augOperation ? "Y":"N",
            "sepOperation": obj?.sepOperation ? "Y":"N",
            "octOperation": obj?.octOperation ? "Y":"N",
            "novOperation": obj?.novOperation ? "Y":"N",
            "decOperation": obj?.decOperation ? "Y":"N"
          };
        }

        if (orderOptionNames && orderOptionNames.length) {
            const obj = orderOptionNames.reduce((accumulator, value) => {
            return {...accumulator, [value.id]: 'Y'};
          }, {});
          selectedOrderOption = {
            "curbsidePickup": obj?.curbsidePickup ? "Y" : "N",
            "delivery": obj?.delivery ? "Y" : "N",
            "inPerson": obj?.inPerson ? "Y" : "N",
            "onlinePreselection": obj?.onlinePreselection ? "Y" : "N",
            "phonePreselection": obj?.phonePreselection ? "Y" : "N",
            "otherType": obj?.otherType ? "Y" : "N",
          };
        }

        if (dayNames && dayNames.length) {
            const obj = dayNames.reduce((accumulator, value) => {
            return {...accumulator, [value.id]: 'Y'};
          }, {});
          selectedDays = {
            "monOperation": obj?.monOperation ? "Y":"N",
            "tueOperation": obj?.tueOperation ? "Y":"N",
            "wedOperation": obj?.wedOperation ? "Y":"N",
            "thuOperation": obj?.thuOperation ? "Y":"N",
            "friOperation": obj?.friOperation ? "Y":"N",
            "satOperation": obj?.satOperation ? "Y":"N",
            "sunOperation": obj?.sunOperation ? "Y":"N"        
          };
        }
        let payload = {
            "cityName": (selectedCity?.id === "all") ? "" : (selectedCity?.id === "myLocation") ? coordinates?.city : selectedCity?.label,
            "miles": (selectedCity.id === "all") ? "" : selectedArea.id,
            "lat": parseFloat(coordinates?.lat),
            "lon": parseFloat(coordinates?.lng),
            "allCityFlag": (selectedCity?.id === "all") ? "Y" : "N",
            "vendorId": selectedVendors?.id === "all" ? "" : selectedVendors?.id,
            "csaPickupSite": selectedType?.csaPickupSite,
            "farmStand": selectedType?.farmStand,
            "farmersMarket": selectedType?.farmersMarket,
            "farmersMarketBooth": selectedType?.farmersMarketBooth,
            "mobileMarket": selectedType?.mobileMarket,
            "monthsOperation": {
                "janOperation": selectedMonths?.janOperation,
                "febOperation": selectedMonths?.febOperation,
                "marOperation": selectedMonths?.marOperation,
                "aprOperation": selectedMonths?.aprOperation,
                "mayOperation": selectedMonths?.mayOperation,
                "junOperation": selectedMonths?.junOperation,
                "julOperation": selectedMonths?.julOperation,
                "augOperation": selectedMonths?.augOperation,
                "sepOperation": selectedMonths?.sepOperation,
                "octOperation": selectedMonths?.octOperation,
                "novOperation": selectedMonths?.novOperation,
                "decOperation": selectedMonths?.decOperation
            },
            "distributionOption": {
                "delivery": selectedOrderOption?.delivery,
                "curbsidePickup": selectedOrderOption?.curbsidePickup,
                "onlinePreselection": selectedOrderOption?.onlinePreselection,
                "phonePreselection": selectedOrderOption?.phonePreselection,
                "inPerson": selectedOrderOption?.inPerson,
                "otherType": selectedOrderOption?.otherType
            },
            "monOperation": selectedDays?.monOperation,
            "tueOperation": selectedDays?.tueOperation,
            "wedOperation": selectedDays?.wedOperation,
            "thuOperation": selectedDays?.thuOperation,
            "friOperation": selectedDays?.friOperation,
            "satOperation": selectedDays?.satOperation,
            "sunOperation": selectedDays?.sunOperation,
            "openToday": openToday === "Yes" ? "Y":"N",
        };
        // console.log("payloadss:::",payload);
        // console.log("selectedCity:::",selectedCity);
        // console.log("coordinates:::",coordinates);
        props.mapSearchPayload(payload);
        props.getMapStoresList(payload);
        props.updateBooleanValue(true);
        props.isClearSearch(false);
        props.searchProperties(searchProperties);
        if (selectedCity?.id === "all") {
            props.coordinates(defaultLocation);
        } else {
            props.coordinates(coordinates);
        }
        setIsLoading(true)
        // setTimeout(() => {
        //     setIsLoading(false);
        //   }, 2000);
    };

    const renderModal = () => {
       return language.LOC_PERMISSION_DENIED;
    }

    const toggleLocation = () => {
        setCookie('loc_permission_modal', true, { expires: getNextDate()});
        toggleModal(false);
    }

    useEffect(() => {
        setTimeout(()=>{
            setIsLoading(false);
        },2000);
    }, [props?.mapdata?.storesList]);

    return (
        <div className="row p-0">
            { showLocationModal &&
                <ModalComponent
                    shown = {showLocationModal}
                    close = {() => {
                    setShowLocationModal(false);
                    }}
                    title = {language["Uses Location Services"]}
                    modalBody = {renderModal}
                    size={"lg"}
                    label={language["Uses Location Services"]}
                />
            }
            <Modal
                shown={modalShown}
                close={() => {
                    toggleModal(false);
                }}
            >
                <div>
                    <div className="modal-header">
                        <span><i className="fa fa-map-pin"></i></span>
                        <span> {language["Uses Location Services"]}</span>
                    </div>
                    <div className="modal-body" style={{paddingTop: "10px"}}>
                        <div className="row">
                            <div className="col-md-12">
                                <p className="f_weight" style={{fontSize: "20px"}}>{language.LOC_PERMISSION_DENIED}</p>
                            </div>
                        </div>
                    </div>
                    <button type="button" className="btn btn-outline-primary" onClick={toggleLocation} data-dismiss="modal" aria-label="Close">
                        Close
                    </button>
                </div>
            </Modal>

            <div className="col-md-12 mb-2">
                <h2 tabIndex={0} className='font-bold boldFont pt-3' style={{fontSize:"24px"}} id="search-criteria-heading">
                    {language["Search Filters"]}
                </h2>
                {/* <p className="sr-only">Select one or more search criteria and click search.</p> */}
                <div className="row">
                    <div className="col-md-3 mt-2 mb-2" id={"city_select"}>
                        <label htmlFor="deliveryCity">
                            <small className="boldFont">{language.County}/{language.City}</small>
                        </label>
                        <SelectDropdown label="County/City" dataList={cityList} defaultValue={selectedCity} onChange={handleCityChange} />
                    </div>

                    <div className="col-md-3 mt-2 mb-2">
                        <label htmlFor="areaWithin">
                            <small className="boldFont">{language["Area Within"]}</small>
                        </label>
                        <SelectDropdown label="Area Within" disabled={selectedCity.id === "all"} dataList={areaWithinList || []} defaultValue={selectedArea} onChange={handleAreaChange} />
                    </div>

                    <div className="col-md-3 mt-2 mb-2">
                        <label htmlFor="vendorType">
                            <small className="boldFont">{language["Type"]}</small>
                        </label>
                        <div className="multiselect-wrapper" id={"type_multiSelect"} >
                            <div className="multiselect-input-div">
                                <MultiSelect options={vendorType} value={typeNames} onChange={handleTypeChange} label="Types" language={language} />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 mt-2 mb-2">
                        <label htmlFor="orderOfOperation">
                            <small className="boldFont">{language["Order Option(s)"]}</small>
                        </label>
                        <div className="multiselect-wrapper" id={"order_multiSelect"} >
                            <div className="multiselect-input-div">
                                <MultiSelect options={orderOptions} value={orderOptionNames} onChange={handleOrderOptionsChange} label="Order Of Operations" language={language} />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 mt-2 mb-2">
                        <label htmlFor="monthOfOperation">
                            <small className="boldFont">{language["Month(s) of Operation"]}</small>
                        </label>
                        <div className="multiselect-wrapper" id={"month_multiSelect"} >
                            <div className="multiselect-input-div">
                                <MultiSelect options={monthsOfOperation} value={monthNames} onChange={handleMonthsChange} label="Months of Operation" language={language} />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 mt-2 mb-2">
                        <label htmlFor="dayOfOperation">
                            <small className="boldFont">{language["Day(s) of Operation"]}</small>
                        </label>
                        <div className="multiselect-wrapper" id={"day_multiSelect"} >
                            <div className="multiselect-input-div">
                                <MultiSelect options={daysOfOperation} value={dayNames} onChange={handleDayChange} label="Days of Operation" language={language} />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 mt-2 mb-2">
                        <label htmlFor="vendorName">
                            <small className="boldFont">{language["Vendor Name"]}</small>
                        </label>
                        <SelectDropdown label="Vendor name" dataList={vendorList} defaultValue={selectedVendors} onChange={handleVendorChange} />
                    </div>
                    <div className="col-md-3 mt-2 mb-2">
                        <label htmlFor="OpenToday" style={{ display: "block" }}>
                            <small className="boldFont">{language["Open Today"]}</small>
                        </label>
                        <Select
                            className="shopAvailable-select"
                            style={customStyles.select}
                            value={openToday}
                            onChange={handleChange}
                            inputProps={{ "aria-label": 'open Today' }}
                        >
                            <MenuItem className="language-menu-item" value={"Yes"}>{language["Yes"]}</MenuItem>
                            <MenuItem className="language-menu-item" value={"No"}>{language["No"]}</MenuItem>
                        </Select>
                    </div>
                </div>
            </div>
            <div className="col-md-12 mb-3 text-center">
                <StyledButton onClick={handleButtonClick} variant={"primary"} text={language["Search"]} label={"search"} isIcon="true" faIcon="fa fa-search" direction="left" />
                <StyledButton onClick={handleClearSearch} variant={"outline"} text={language["Clear Search"]} isIcon="true" faIcon="fa fa-refresh" direction="left" />  
            </div>
            {props.resultShown && (
               <h2 tabIndex={0} className='font-bold boldFont pt-3' style={{fontSize:"24px"}} id="result-criteria-heading">
                    {language["Results Section"]}
                </h2>
            )}
        </div>

    )
}

SearchComponent.propTypes = {
    updateBooleanValue: PropTypes.func,
    mapdata: PropTypes.object,
    language: PropTypes.object,
    getCityList: PropTypes.func.isRequired,
    getVendorList: PropTypes.func.isRequired,
    getMapStoresList: PropTypes.func,
    searchProperties: PropTypes.func,
    coordinates: PropTypes.func,
    // modalShown: PropTypes.func,
    showLocationModal: PropTypes.func,
    setIsLoading: PropTypes.func,
    mapSearchPayload: PropTypes.func.isRequired,
    label : PropTypes.string,
    resultShown : PropTypes.bool,
    isClearSearch: PropTypes.func
}

const mapDispatchToProps = (dispatch) => {
    return {
        getMapData: (searchProperties) => {
            dispatch({ type: GET_MAPDATA, search: searchProperties });
        },
        getCityList: (city_county) => {
            dispatch({ type: GET_COUNTYCITY, city:city_county });
        },
        getVendorList: (payload) => {
            dispatch({ type: GET_VENDOR_LIST, payload:payload });
        },
        getMapStoresList: (payload) => dispatch({ type: GET_STORE_LIST, payload: payload }),
        mapSearchPayload: (payload) => dispatch({ type: SET_MAP_SEARCH, payload: payload }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(SearchComponent);