import React, {useState, useEffect} from 'react';
import { Grid, Link as MaterialLink, Collapse, Button } from '@mui/material';

import { connect } from "react-redux";
import { mapStateToProps } from "../../redux/reducers";
import PropTypes from "prop-types";

import BellIcon from '../../assets/images/bell-icon.svg';
import AlertSeperator from '../../assets/images/alert-seperater-line.svg';
import '../../styles/yellowBanner.scss';
import { getBanner } from '../../redux/actionTypes/banner';

let selectedLanguage = localStorage.getItem("selectedLanguage")

if (selectedLanguage === null) {
  selectedLanguage = "en";
} else {
  selectedLanguage = selectedLanguage?.split("-")?.[0];
}

const YellowBanner = (props) => {
  const [language, setLanguage] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [isEnableAlert, setIsEnableAlert] = useState(false);
  const [yellowBanner, setYellowBanner] = useState([]);

  // useEffect(()=>{
  //  props.getBanner();
  // },[]);

  const handleAlert = () => {
    setShowAlert((prev) => !prev)
  }

  // useEffect(()=>{ 
  //   if (props?.banner && props?.banner?.banner?.data?.length) {
  //     setIsEnableAlert(true);
  //     const data = props?.banner?.banner?.data;
  //     const selectedLangBanner = data.find(item=>item.language == selectedLanguage);
  //     setYellowBanner(selectedLangBanner.dsc);
  //   }
  // },[props.banner]);

  useEffect(()=>{
    const bannerData = [{"id":1,"code":"YELLOW","dsc":"The mobile version of DTA Finder is currently being updated to improve the user experience of all clients. Please check back soon.","language":"en"},
    {"id":2,"code":"YELLOW","dsc":"La versión móvil de DTA Finder se está actualizando actualmente para mejorar la experiencia de usuario de todos los clientes. Por favor, revise luego.","language":"es"},
    {"id":3,"code":"YELLOW","dsc":"A versão móvel do DTA Finder está sendo atualizada para melhorar a experiência do usuário de todos os clientes. Por favor, volte em breve.","language":"pt"},
    {"id":4,"code":"YELLOW","dsc":"DTA Finder 的移动版本目前正在更新，以改善所有客户端的用户体验。请尽快回来查看。","language":"zh"},{"id":5,"code":"YELLOW","dsc":"Phiên bản di động của DTA Finder hiện đang được cập nhật để cải thiện trải nghiệm người dùng của tất cả khách hàng. Làm ơn hãy kiểm tra lại sớm.","language":"vi"}];
    // const bannerData = []; // To enable the line when you need to hide the yellow banner
    if (bannerData && bannerData.length) {
      const selectedLangBanner = bannerData.find(item=>item.language == selectedLanguage);
      setYellowBanner(selectedLangBanner.dsc);
      setIsEnableAlert(true);
    }
  },[]);

  useEffect(() => { 
    setLanguage(props.language)
  }, [props.language])
    return (
      <>
      { isEnableAlert && (<>
        <Grid container display={{md: 'block' }} className="yellow-wrapper">
          <Grid container maxWidth="lg" className="yellow-wrapper-banner">
            <Grid item xs={12} sx={{ mx: 2 }} display={{ md: 'block' }} className="banner div-center" >
              <Grid item xs={12} sx={{ mx: 2}} display="flex" alignItems="center" className="alerts-outer">
                <Grid item xs={12} sm={9} lg={9} md={9} className="div-center alerts-inner" display="flex" justifyContent="flex-start"
                  alignItems="center"
                  fontSize={{ xs: '14px', lg: '24px', md: '24px' }}>
                  <img src={BellIcon} alt="Alert Bell Logo" height="27" width="30" style={{ marginTop: 5, marginRight: 10 }} />
                  <span className="ml-08 alert-fnt alert-txt">{language?.alerts}</span>
                  <img className="ml-08 mr-08 alert-separator" src={AlertSeperator} alt="Separator line" height="25" />
                  <p className="ml-08 alert-fnt alert-update">{language?.alertHeading}</p>
                </Grid>
                <Grid item xs={12} sm={3} lg={3} md={3} display="flex" justifyContent="flex-end"  className="margib-btn alert-btn">
                  <Button className="alert-black-button" onClick={handleAlert}>
                    {showAlert ? language?.hideAlerts : language?.showAlerts}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Collapse in={showAlert} sx={{ background: '#fef9e8' }} id="yellowBanner" classes={{ wrapper: 'alignCenter' }}>
          <Grid container maxWidth="lg" className="wrapper-banner" sx={{ display: showAlert ? 'block' : 'none'}}>
            <Grid item xs={12} sx={{ mx: 2 }}  className="banner div-center">
              <Grid item xs={12} sx={{ mx: 2 }} display="flex" alignItems="center" className="alerts-outer">
                <Grid item xs={12} sm={12} lg={12} md={12} className="div-center alerts-inner" display="flex" justifyContent="flex-start"
                  alignItems="center" fontSize={{ xs: '14px', lg: '24px', md: '24px' }}>
                  <Grid className="banner-txt" item xs={12} lg={12} md={12} textAlign="justify" color={'#555555'} >
                    <p dangerouslySetInnerHTML={{ __html: yellowBanner}} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Collapse>
        </>
      )}
      </>
    )
}

const mapDispatchToProps = (dispatch) => {
  return {
    // getBanner: (payload) => dispatch(getBanner(payload)),
  }
}

YellowBanner.propTypes = {
  language: PropTypes.object,
  data: PropTypes.object,
  isEnableAlert: PropTypes.bool,
  // getBanner: PropTypes.func,
  // banner: PropTypes.object
};

export default connect( mapStateToProps, mapDispatchToProps )(YellowBanner);