import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import { mapStateToProps } from "../../redux/reducers";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import Button from 'react-bootstrap/Button';
import {Row, Col} from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { sendSupportEmail } from "../../redux/actionTypes/registration";
// import SelectDropdown from "../common/selectdropdown-component";
import DateTimePicker from "../common/components/dateTimePicker";

import "../../styles/help.scss";
import { maskMobile, removeSpecialCharacter } from "../../utils/globalMethods";
import { FormControl, MenuItem, Select } from "@mui/material";
import ModalComponent from "../common/components/modal";
import SelectDropdown from "../../components/SelectDropdown";
import { GET_VENDOR_LIST } from "../../redux/actionTypes";


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

const HelpComponent = (props) => {
    const { shown, close, language, sendSupportEmail} = props
    const selectStyle ={
        fontWeight:'800',
        fontSize:'1rem',
        fontFamily :'inherit !important',
    }
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [category, setCategory] = useState('');
    const [vendorList, setVendorList] = useState([]);
    const [selectedVendors, setSelectedVendors] = useState("");
    const [dateOfVisit, setDateOfVisit] = useState(new Date().getTime());

    const { register, handleSubmit, setValue, clearErrors, watch, formState: { errors }, reset} = useForm();
    
    useEffect(() => {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, []);

    const onSubmit = data => {
        if(Object.keys(errors).length === 0){
            sendSupportEmail(data);
            close() 
        } 
    };

    const mapVendorList = (vendors) =>{ 
        if(vendors.length){
          let vendorOptions = vendors.map((item) => {
            return {
              id: item.vendorId,
              name: removeSpecialCharacter(item.vendorName), 
              values: removeSpecialCharacter(item.vendorName),
              isActive: 1
            };
          })
          setVendorList(vendorOptions);
        }
    };

    useEffect(() =>{
        props.getVendorList({
            "cityName": "",
            "miles": "",
            "lat": "",
            "lon": "",
            "allCityFlag": "Y"
        });
    },[])
    
    useEffect(() => {
            mapVendorList(props.mapdata.vendor);
    },[category]);

    const renderCategory = (category) => {
        setCategory(category);
        setSelectedVendors('');
        setDateOfVisit('');
        reset();
    }
    const handleKeyPress = (event,category) => {
        console.log(event.key);
        if (event.key === 'Enter') {
        setCategory(category);
          
        }
      };
   
    const HowToUseTheMap = () => {

        const getDeviceType = () => {
            let deviceType = language["DesktopView"];
            if (windowDimensions.width <= 768) {
                deviceType = language["MobileView"];
                return deviceType
            }
            return deviceType;
        }  
        const deviceType = getDeviceType();

        return (<>
            <div className="video-responsive col-md-12">
                <iframe width="100%" height="400" id="useMapVideo" src={`https://www.youtube.com/embed/${deviceType}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> 
                <Form.Text tabIndex={0} onKeyPress={(event) => handleKeyPress(event,'Other')} muted onClick={() => { renderCategory('Other') }} className="cursor-pointer">
                    {language["Need additional help?"]}
                </Form.Text> 
            </div>
        </>);
    }

    const QuestionAboutDTACase = () => {
        return (<p className="font-light14">{language["ForInformationRegardingYourDTACase"]} <a href="https://dtaconnect.eohhs.mass.gov/" target="_blank"  rel="noopener noreferrer"> {language["mass link"]}</a> {language["You may also call the DTA Assistance Line at"]} <b><a href="tel:(877)382-2363"> 877-382-2363 </a></b> {language["and speak with a case manager."]}</p>);
    }
    
    const HowToUseHIP = () => {
        return (
            <>
                <p className="font-light14">{language["Help Title"]}</p>
                <ListGroup as="ul" className="howtousehip font-light14">
                    <ListGroup.Item as="li">{language["Help Text1"]}</ListGroup.Item>
                    <ListGroup.Item as="li">{language["Help Text2"]}</ListGroup.Item>
                    <ListGroup.Item as="li">{language["Help Text3"]}</ListGroup.Item>
                    <ListGroup.Item as="li">{language["Help Text4"]} <a href="http://mass.gov/hip" target="_blank" rel="noopener noreferrer">{language["gov link"]}</a></ListGroup.Item>
                </ListGroup>
                <Form.Group>
                    <Form.Text muted >
                        <a href="http://mass.gov/snap" target="_blank" rel="noopener noreferrer">{language["Help Note"]}</a>
                    </Form.Text>
                </Form.Group>
                <Form.Group>
                <Form.Text muted tabIndex={0} onKeyPress={(event) => handleKeyPress(event,'Other')} onClick={() => { renderCategory('Other') }} className="cursor-pointer addtional-help">
                    {language["Need additional help?"]}
                </Form.Text>
                </Form.Group>
            </>
        );
    } 

    const UserContactInfo = () => {
        const [mobile, setMobile] = useState("");

        return (<Card className="mb-3">
            <Card.Body>
                <Card.Title className="resText font-medium16">{language["User Contact Info"]}</Card.Title>
                {/* <Card.Text> */}
                    <Row>
                        <Col md={4} sm={6}>
                            <Form.Group as={Col} controlId="formGridFirstName">
                                <Form.Label className="font-medium14">{language["First Name"]}</Form.Label>
                                <Form.Control className="font-light14" type="text"  {...register("firstName")} />
                            </Form.Group>
                        </Col>
                        <Col md={4} sm={6}>
                            <Form.Group as={Col} controlId="formGridLastName">
                                <Form.Label className="font-medium14">{language["Last Name"]}</Form.Label>
                                <Form.Control className="font-light14" type="text" {...register("lastName")} />
                            </Form.Group>
                        </Col>
                        <Col md={4} sm={6}>
                        <Form.Group as={Col} controlId="formPhone">
                            <Form.Label className="font-medium14">Phone</Form.Label>
                            <Form.Control className="font-light14" type="tel" size="lg" value={mobile}
                            {...register("phone", {pattern: {
                              value:
                                /^\s*[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})\s*$/,
                              message: "Invalid phone number",
                            },
                            })}
                            onChange={(e) =>
                                setMobile(maskMobile(e.target.value))
                            }
                            placeholder="(___) ___-____" />
                        </Form.Group>
                        </Col>
                        <Col md={4} sm={6}>
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label className="font-medium14">{language["Email Address"]}</Form.Label>
                                <Form.Control className="font-light14 lowercase" type="text" {...register("email", { required: false,
                                    pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: "Invalid email format"
                                }})} />
                            </Form.Group>
                        </Col>
                    </Row>
                {/* </Card.Text> */}
            </Card.Body>
        </Card>)
    }
    
    const ReportABug = () => {
        return (<>
        <Col className="text-muted font-light15">{language["Please provide the details listed below, so that we can help you better"]}</Col>
        <Card className="mb-3">
            <Card.Body>
                <Card.Title className="resText font-medium16">{language["Device Info"]}</Card.Title>
                <Card.Text>
                    <Row>
                        <Col md={4}>
                            <Form.Group as={Col} controlId="formGridDevice">
                                <Form.Label className="font-medium14">{language["Device"]} *</Form.Label>
                                <FormControl fullWidth>
                                    <Select
                                        {...register("device", { required: "Please select Device" })}
                                        labelId="demo-simple-select-label-Device"
                                        id="demo-simple-select-Device"
                                        style={{background: "#fff"}}
                                        value={watch("device") || ""}
                                        className="font-light14"
                                        displayEmpty
                                        onChange={(e) => {
                                            setValue("device", e.target.value);
                                            clearErrors("device"); 
                                          }}
                                    >
                                        <MenuItem value="" disabled hidden>-- Select --</MenuItem>
                                        <MenuItem value="iPhone">{language["iPhone"]}</MenuItem>
                                        <MenuItem value="iPad">{language["iPad"]}</MenuItem>
                                        <MenuItem value="iOS laptop or desktop">{language["iOS laptop or desktop"]}</MenuItem>
                                        <MenuItem value="Android phone">{language["Android phone"]}</MenuItem>
                                        <MenuItem value="Android tablet">{language["Android tablet"]}</MenuItem>
                                        <MenuItem value="PC">{language["PC"]}</MenuItem>
                                        <MenuItem value="I don`t know">{language["I don't know"]}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridBrowser">
                                <Form.Label className="font-medium14">{language["Browser"]} *</Form.Label>
                                <FormControl fullWidth>
                                    <Select
                                        {...register("browser", { required: "Please select Browser" })}
                                        labelId="demo-simple-select-label-browser"
                                        id="demo-simple-select-browser"
                                        style={{background: "#fff"}}
                                        value={ watch("browser") || ""}
                                        className="font-light14"
                                        displayEmpty
                                        onChange={(e) => {
                                            setValue("browser", e.target.value);
                                            clearErrors("browser");
                                          }}
                                        >
                                        <MenuItem value="" disabled hidden>-- Select --</MenuItem>
                                        <MenuItem value="Chrome">{language["Chrome"]}</MenuItem>
                                        <MenuItem value="Edge">{language["Edge"]}</MenuItem>
                                        <MenuItem value="Firefox">{language["Firefox"]}</MenuItem>
                                        <MenuItem value="Internet Explorer">{language["Internet Explorer"]}</MenuItem>
                                        <MenuItem value="Safari">{language["Safari"]}</MenuItem>
                                        <MenuItem value="I don`t know">{language["I don't know"]}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Form.Group>
                        </Col>
                        <Col md={8}>
                            <Form.Group as={Col} controlId="formDescription">
                                <Form.Label className="font-medium14">{language["Description"]} *</Form.Label>
                                <Form.Control
                                    className="font-light14"
                                    as="textarea"
                                    placeholder=""
                                    style={{ height: '114px' }}
                                    maxLength="500"
                                    {...register("description", { required: "Description is required",maxLength: 500 })}
                                    />
                                <Form.Text id="formDescription" className="float-right font-light13" muted>
                                    <i className="fa fa fa-info-circle ml-2" aria-hidden="true"></i> {language["Max character"]}: 500
                                </Form.Text>
                            </Form.Group>
                        </Col>
                    </Row>
                </Card.Text>
            </Card.Body>
        </Card>
        <UserContactInfo />
        <Form.Control type="text" value={"Bug"} hidden {...register("type", { required: true })} />
        <Button variant="primary" type="submit" className="float-right">
            {language["Submit"]}
        </Button>
        </>)
    }
    
    const ReportInaccurateInformation = ({vendorList, selectedVendors, setSelectedVendors}) => {
        return (<>
        <Col className="text-muted font-light15">{language["Please provide the details listed below, so that we can help you better"]}</Col>
        <Card className="mb-3">
            <Card.Body>
                <Card.Title className="resText font-medium16">{language["Vendor Info"]}</Card.Title>
                <Card.Text>
                    <Row>
                        <Col md={4}>
                            <Form.Group as={Col} controlId="formGridDevice">
                                <Form.Label className="font-medium14">{language["Vendor Name"]} *</Form.Label>
                                <SelectDropdown
                                    label="Vendor Name"
                                    {...register("vendorName", { required: "Please select Vendor" })}
                                    defaultValue={selectedVendors}
                                    onChange={(v) => {
                                        setSelectedVendors(v);
                                        setValue(`vendorName`, v.name);
                                        clearErrors('vendorName')
                                    }}
                                    dataList={vendorList}

                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridBrowser">
                                <Form.Label className="font-medium14 mt-2">{language["Date and time of visit"]} *</Form.Label>
                                <DateTimePicker
                                    // className="font-light14"
                                    handleChange={(newValue) => {
                                        setDateOfVisit(newValue.unix()*1000);
                                        setValue(`date`,newValue.format("MM/DD/YYYY hh:mm a"));
                                    }}
                                    value={dateOfVisit}
                                    mode={"help"}
                                    {...register("date")}
                                    maxDate={new Date().getTime()}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={8}>
                            <Form.Group as={Col} controlId="formDescription">
                                <Form.Label className="font-medium14">{language["Please provide the location and a brief description"]} *</Form.Label>
                                <Form.Control
                                    className="font-light14"
                                    as="textarea"
                                    placeholder=""
                                    style={{ height: '114px' }}
                                    maxlength="500"
                                    {...register("description", { required: "Description is required",maxLength: 500 })}
                                    />
                                <Form.Text id="formDescription" className="float-right font-light13" muted>
                                    <i className="fa fa fa-info-circle ml-2" aria-hidden="true"></i> {language["Max character"]}: 500
                                </Form.Text>
                            </Form.Group>
                        </Col>
                    </Row>
                </Card.Text>
            </Card.Body>
        </Card>
        <UserContactInfo />
        <Form.Control type="text" value={"inaccurateinfo"} hidden {...register("type", { required: true })} />
        <Button variant="primary" type="submit" className="float-right">
            {language["Submit"]}
        </Button></>)
    }

    ReportInaccurateInformation.propTypes = {
        vendorList: PropTypes.array,
        selectedVendors: PropTypes.object,
        setSelectedVendors: PropTypes.func
    };
    
    const HelpForHIPVendors = ({vendorList, selectedVendors, setSelectedVendors}) => {
        return (<>
        <Col className="text-muted font-light15">{language["Please provide the details listed below, so that we can help you better"]}</Col>
        <Card className="mb-3">
            <Card.Body>
                <Card.Title className="resText font-medium16">{language["Vendor Info"]}</Card.Title>
                <Card.Text>
                    <Row>
                        <Col md={4}>
                            <Form.Group as={Col} controlId="formGridDevice">
                                <Form.Label className="font-medium14">{language["Vendor Name"]} *</Form.Label>
                                <SelectDropdown
                                    label="Vendor Name"
                                    {...register("vendorName", { required: "Please select Vendor" })}
                                    defaultValue={selectedVendors}
                                    onChange={(v) => {
                                        setSelectedVendors(v);
                                        setValue(`vendorName`, v.name);
                                        clearErrors('vendorName')

                                    }}
                                    dataList={vendorList}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group as={Col} controlId="formDescription">
                                <Form.Label className="font-medium14">{language["Description"]} *</Form.Label>
                                <Form.Control
                                    className="font-light14"
                                    as="textarea"
                                    placeholder=""
                                    style={{ height: '114px' }}
                                    maxlength="500"
                                    {...register("description", { required: "Description is required",maxLength: 500 })}
                                    />
                                <Form.Text id="formDescription" className="float-right font-light13" muted>
                                    <i className="fa fa fa-info-circle ml-2" aria-hidden="true"></i> {language["Max character"]}: 500
                                </Form.Text>
                            </Form.Group>
                        </Col>
                    </Row>
                </Card.Text>
            </Card.Body>
        </Card>
        <UserContactInfo />
        <Form.Control type="text" value={"helpvendors"} hidden {...register("type", { required: true })} />
        <Button variant="primary" type="submit" className="float-right">
            {language["Submit"]}
        </Button></>)
    }

    HelpForHIPVendors.propTypes = {
        vendorList: PropTypes.array,
        selectedVendors: PropTypes.object,
        setSelectedVendors: PropTypes.func
    };
    
    const Other = () => {
        return (<>
        <Col className="text-muted font-light15">{language["Please provide the details listed below, so that we can help you better"]}</Col>
        <Card className="mb-3">
            <Card.Body>
                <Card.Text>
                    <Row>
                        <Col md={12}>
                            <Form.Group as={Col} controlId="formDescription">
                                <Form.Label className="font-medium14">{language["Description"]} *</Form.Label>
                                <Form.Control
                                    className="font-light14"
                                    as="textarea"
                                    placeholder=""
                                    style={{ height: '114px' }}
                                    maxlength="500"
                                    {...register("description", { required: "Description is required",maxLength: 500 })}
                                    />
                                <Form.Text id="formDescription" className="float-right font-light13" muted>
                                    <i className="fa fa fa-info-circle ml-2" aria-hidden="true"></i> {language["Max character"]}: 500
                                </Form.Text>
                            </Form.Group>
                        </Col>
                    </Row>
                </Card.Text>
            </Card.Body>
        </Card>
        <UserContactInfo />
        <Form.Control type="text" value={"Other"} hidden {...register("type", { required: true })} />
        <Button variant="primary" type="submit" className="float-right">
            {language["Submit"]}
        </Button></>)
    }

    const renderModal = () =>{
        return(
            <Form onSubmit={handleSubmit(onSubmit)}>
             <Form.Group as={Row} className="mb-3 select-category">
                 <Form.Label column sm={4} className="font-semibold16">{language["Choose Category"]} * </Form.Label>
                 <Col sm={6}>
                    <FormControl fullWidth>
                        <Select sx={{...selectStyle}}
                            displayEmpty
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={category}
                            inputProps={{ 'aria-label': 'Choose Category' }}
                            onChange={e => {
                                renderCategory(e.target.value);
                            }}
                        >
                            <MenuItem value="" disabled="" selected="" >-- {language["Select"]} --</MenuItem>
                            <MenuItem value="How to use the map">{language["How to use the map"]}</MenuItem>
                            <MenuItem value="How to use HIP">{language["How to use HIP"]}</MenuItem>
                            <MenuItem value="Report a bug">{language["Report a bug"]}</MenuItem>
                            <MenuItem value="Report inaccurate information">{language["Report inaccurate information"]}</MenuItem>
                            <MenuItem value="Question about DTA case">{language["Question about DTA case"]}</MenuItem>
                            <MenuItem value="Help for HIP vendors">{language["Help for HIP vendors"]}</MenuItem>
                            <MenuItem value="Other">{language["Other"]}</MenuItem>
                        </Select>
                    </FormControl>
                </Col>
            </Form.Group>
            {Object.keys(errors).length > 0 && 
                <span className="text-danger font-regular16"><i className="fa fa-exclamation-circle"></i>&nbsp;{errors[Object.keys(errors)[0]].message}</span>
            }
            {category === "" && ""}
            {category === "How to use the map" && <HowToUseTheMap />}
            {category === "How to use HIP" && <HowToUseHIP />}
            {category === "Report a bug" && <ReportABug />}
            {category === "Question about DTA case" && <QuestionAboutDTACase />}
            {category === "Report inaccurate information" && <ReportInaccurateInformation 
                vendorList={vendorList}
                selectedVendors={selectedVendors} 
                setSelectedVendors={setSelectedVendors}
            />}
            {category === "Help for HIP vendors" && <HelpForHIPVendors 
                vendorList={vendorList}
                selectedVendors={selectedVendors} 
                setSelectedVendors={setSelectedVendors}
            />}
            {category === "Other" && <Other />}
        </Form>
        )
    }

  return (
    <ModalComponent 
    shown = {shown}
    close = {() => close()}
    title = {language["help"]}
    modalBody = {renderModal}
    size={"lg"}
    id={"helpModal"}
    centered={true}
    label={language["help"]}
  />
  
  );
};

HelpComponent.propTypes = {
  shown: PropTypes.bool,
  close: PropTypes.func,
  language: PropTypes.object,
  vendors: PropTypes.array,
  mapdata: PropTypes.object,
  sendSupportEmail: PropTypes.func,
  getVendorList: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
    return {
        sendSupportEmail: (payload) => dispatch(sendSupportEmail(payload)),
        getVendorList: (payload) => {
            dispatch({ type: GET_VENDOR_LIST, payload:payload });
        },
      };
}

export default connect(mapStateToProps,mapDispatchToProps)(HelpComponent);
