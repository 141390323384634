import React, {
    useState, Fragment,
    useEffect
} from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useForm, useFieldArray } from "react-hook-form"; //, Controller
import PropTypes from "prop-types";
import { mapStateToProps } from "../../../redux/reducers";
import { connect } from "react-redux";
import { UPDATE_VENDOR } from "../../../redux/actionTypes/vendordetails";
import {PUT_USER} from "../../../redux/actionTypes/registration";
import "../../../styles/registration.scss";
import { convertUTCDateToLocalDate, isNumber, maskMobile, removeSpecialCharacter } from "../../../utils/globalMethods";
import {  getVendorInfoList } from "../../../redux/actionTypes/vendordetails";
import CommonLoading from "../../common/components/commonLoading";

const VendorDetailsTab = (props) => {
    let initial = {
        vendorName: "",
        fnsNum: "",
        firstName: "",
        lastName: "",
        primaryMobile: "",
        primaryEmail: "",
        createdBy: "",
        createTs: "",
        lastUpdtTs: "",
        adminLastUpdtTs: "",
        lastUpdtUidNam: "",
        lastUpdtUid: "",
        additionalContactInfo: "",
        vendorHipId: ""
    };

    const [mode, setMode] = useState("view");
    const [mobile, setMobile] = useState("");
    const [update, setUpdate] = useState(false);
    const [formValue, setFormValue] = useState(initial);
    const [loading, setLoading] = useState(false);

    const {
        register,
        formState: { errors },
        handleSubmit,
        control,
        watch,
        reset
    } = useForm({
        defaultValues: formValue
    });

    useEffect(() => {
        if (props.vendorInfo) {
            const formData = props.vendorInfo;
            if (typeof formData.fnsNum === "string") {
                const format = formData.fnsNum.split(",").map((item, i) => ({id:i,name: item }));
                formData.fnsNum = format;
            }
            setTimeout(() => {
                if (formData?.primaryMobile) {
                    setMobile(maskMobile(formData?.primaryMobile));
                }
                reset(props.vendorInfo);
            }, 1000);
            props.vendorInfo.lastUpdtUid = props.vendorInfo.lastUpdtUidNam;
            props.vendorInfo.publicMobile = maskMobile(props.vendorInfo.publicMobile);
            props.vendorInfo.vendorName = removeSpecialCharacter(props.vendorInfo.vendorName);
            setMobile(maskMobile(props.vendorInfo.primaryMobile));
            setFormValue(props.vendorInfo);
        }
    }, [props.vendorInfo]);
   

    const { fields, append, remove } = useFieldArray({
        control,
        name: "fnsNum"
    });
    let watchFieldArray = watch("fnsNum");
    const controlledFields = fields.map((field, index) => {
        return {
            ...field,
            ...watchFieldArray?.[index],
        };
    });
    const [addMore, setAddMore] = useState(1);
  const handleAddFields = () => {
    setAddMore(addMore + 1)
    append({ name: "" });
  };

  const handleRemove = (i) => {
    setAddMore(addMore - 1)
    remove(i);
  }

    const onFormSubmit = (data) => {

        if (data.fnsNum && data.fnsNum.length > 0) {
            data.fnsNum = data.fnsNum.map(item => item.name).toString();
            data.id = data.vendorId;
        }
        setLoading(true)
        let updatedInfo = {
            firstName: data.firstName.trim(),
            lastName: data.lastName.trim(),
            fnsNum: data.fnsNum,
            vendorName: data.vendorName.trim(),
            primaryEmail: data.primaryEmail,
            primaryMobile: data.primaryMobile,
            publicEmail: data.publicEmail ? data.publicEmail.toLowerCase() : "",
            publicMobile: data.publicMobile ? data.publicMobile : "",
            publicWebsite: data.publicWebsite ? data.publicWebsite : "",
            csaText: data.csaText ? data.csaText : "",
            publicPhoto: data.publicPhoto ? data.publicPhoto : "",
            csaPickupSite: data.csaPickupSite ? data.csaPickupSite : "N",
            farmStand: data.farmStand ? data.farmStand : "N",
            farmersMarket: data.farmersMarket ? data.farmersMarket : "N",
            farmersMarketManagers: data.farmersMarketManagers ? data.farmersMarketManagers : "N",
            mobileMarket: data.mobileMarket ? data.mobileMarket : "N",
            forceLogin: data.forceLogin,
            id: data.vendorId,
            csaSnapFlag: data.csaSnapFlag,
            additionalContactInfo: data.additionalContactInfo ? data.additionalContactInfo : "",
            products: data.products ? data.products : "",
            businessDesc: data.businessDesc ? data.businessDesc : "",
            lastUpdateTime: data.lastUpdateTime,
            userId: props.userId,
            submitForm: "Vendor",
            type: "edit"
        }
        if (update) {
            props.updateUser(updatedInfo);
            setFormValue(updatedInfo);
            setUpdate(false);
            setMode("view");
        }
    };

    useEffect(() =>{
        setLoading(false)
    },[props.user])

    return (

        <div className="tab-content-wrapper">
              {loading &&
                <CommonLoading />
            }
            <Form onSubmit={handleSubmit(onFormSubmit)}>
                <Row>
                    <Col>
                        <h3 className="resText" > Vendor Details</h3>
                    </Col>
                    {mode === "view" ? <Col className="txt-right"> 
                            <div onClick={() => setMode("edit")} className="btn btn-primary">
                                <span ><i className="fa fa-pencil"></i> Edit</span>
                            </div>
                        </Col> :
                        <Col md={6} xs={12} sm={6} xl={6} lg={6} className="txt-right">
                            <Button type="submit" onClick={() => {setUpdate(true)}} className="btn btn-primary mb-2 m-r-5">
                                <span ><i className="fa fa-check"></i> Update</span>
                            </Button>
                            <Button
                                onClick={() => {
                                    setMode("view")
                                    if (props?.vendorInfo?.primaryMobile) {
                                        setMobile(maskMobile(props?.vendorInfo?.primaryMobile));
                                    }
                                    reset(props.vendorInfo);
                                }}
                                className="btn btn-primary mb-2">
                                <span ><i className="fa fa-times"></i> Cancel</span>
                            </Button>
                        </Col>
                    }
                </Row>
                <br />
                <Row>
                    <Col md={3} sm={3} xl={3} lg={3}>
                        <div className="form-group mb-0">
                            <label
                                className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                                htmlFor="vendorName"
                            >
                                Farm / Vendor Name *
                            </label>
                            <input
                                {...register("vendorName", {
                                    required: "Farm / Vendor name is required",
                                    validate: (value) => {
                                        if (value.trim() === "") {
                                            return "Farm / Vendor name must contain characters";
                                        }
                                        return true;
                                    }
                                })}
                                // value={vendorDetails?.farmName}
                                className={`form-control form-control-lg required dh-input-box ${errors.vendorName ? "dh-error-box" : ""
                                }`}
                                placeholder=""
                                disabled={mode === "view"}
                            />
                            {errors.vendorName && (
                                <span className="dh-txt-danger">
                                    {errors.vendorName.message}
                                </span>
                            )}
                        </div>
                    </Col>
                    <Col md={9} sm={9} xl={9} lg={9}>
                        <div className="row" id="fnsNumbersList">
                            {controlledFields.map((item, i) => {
                                return (
                                    <Fragment key={`fnsNum.${i}`}>
                                        <div
                                            className="col-md-3"
                                            id={`fnsNum.${i}`}
                                        >
                                            <div className="form-group mb-0">
                                                <label
                                                    className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                                                    htmlFor={`fnsNum.${i}`}
                                                >
                                                    FNS Number *
                                                    {i > 0 && mode === "edit" ? (
                                                        <span className={"text-decoration-underline cursor-pointer green dh-remove ml-3"}
                                                            onClick={() => {
                                                                handleRemove(i);
                                                            }}
                                                        >
                                                            <i className="fa fa-trash-o"></i>{" "}
                                                            Remove
                                                        </span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </label>

                                                <input
                                                    {...register(`fnsNum.${i}.name`, {
                                                        required: true,
                                                        valueAsNumber: true
                                                    })}
                                                    maxLength={7}
                                                    onKeyDown={isNumber}
                                                    // control={control}
                                                    type="text"
                                                    className={`form-control form-control-lg fns-number-input dh-input-box required ${errors.fnsNum?.[i]?.name ? "dh-error-box" : ""
                                                    }`}
                                                    disabled={mode === "view"}
                                                />
                                                {errors.fnsNum?.[i]?.name && (
                                                    <span className="dh-txt-danger">
                                                        FNS number is required
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </Fragment>
                                );
                            })}
                        </div>
                        { mode === 'edit' && addMore < 10 && (
                              <div
                                className="form-text text-muted dh-short-title"
                                id="addMoreFNSNumber"
                              >
                                <span>Do you have an additional FNS number? </span>
                                <span className={"text-decoration-underline cursor-pointer green"} onClick={() => handleAddFields()}>
                                  <i className="fa fa-plus"></i> Add More
                                </span>
                              </div>
                            )}
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col md={3} sm={3} xl={3} lg={3}>
                        <div className="form-group mb-0">
                            <label
                                className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                                htmlFor="firstName"
                            >
                                First Name *
                            </label>
                            <input
                                {...register("firstName", {
                                    required: "First name is required",
                                    validate: (value) => {
                                        if (value.trim() === "") {
                                            return "First name must contain characters";
                                        }
                                        return true;
                                    }
                                })}
                                className={`form-control form-control-lg required dh-input-box ${errors.firstName ? "dh-error-box" : ""
                                }`}
                                placeholder=""
                                disabled={mode === "view"}
                                // value={storeData?.firstName}
                            />
                            {errors.firstName && (
                                <span className="dh-txt-danger">
                                    {errors.firstName.message}
                                </span>
                            )}
                        </div>
                    </Col>
                    <Col md={3} sm={3} xl={3} lg={3}>
                        <div className="form-group mb-0">
                            <label
                                className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                                htmlFor="lastName"
                            >
                                Last Name *
                            </label>
                            <input
                                {...register("lastName", {
                                    required: "Last name is required",
                                    validate: (value) => {
                                        if (value.trim() === "") {
                                            return "Last name must contain characters";
                                        }
                                        return true;
                                    }
                                })}
                                className={`form-control form-control-lg required dh-input-box ${errors.lastName ? "dh-error-box" : ""
                                }`}
                                placeholder=""
                                disabled={mode === "view"}
                                // value={storeData?.lastName}
                            />
                            {errors.lastName && (
                                <span className="dh-txt-danger">
                                    {errors.lastName.message}
                                </span>
                            )}
                        </div>
                    </Col>

                    <Col md={3} sm={3} xl={3} lg={3}>
                        <div className="form-group mb-0">
                            <label
                                className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                                htmlFor="primaryPhoneNumber"
                            >
                                Primary Phone Number *
                            </label>
                            <input
                                {...register("primaryMobile", {
                                    required:
                                        "Primary phone number is required",
                                    pattern: {
                                        value:
                                            /^\s*[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})\s*$/,
                                        message: "Invalid phone number",
                                    },
                                })}
                                type="tel"
                                className={`form-control form-control-lg required dh-input-box ${(errors.primaryMobile)? "dh-error-box" : ""
                                }`}
                                value={mobile}
                                onChange={(e) =>
                                    setMobile(maskMobile(e.target.value))
                                }
                                placeholder="(___) ___-____"
                                id="primaryPhoneNumber"
                                aria-label="primaryPhoneNumber"
                                aria-labelledby="primaryPhoneNumber"
                                aria-describedby="primaryPhoneNumber"
                                required=""
                                // im-insert="true"
                                disabled={mode === "view"}
                            />
                            {(errors.primaryMobile) && (
                                <span className="dh-txt-danger">
                                    {/* {errors.primaryMobile.message} */}
                                    {errors.primaryMobile ? errors.primaryMobile.message : "Primary Phone Number is required"}
                                </span>
                            )}
                        </div>
                    </Col>
                    <Col md={3} sm={3} xl={3} lg={3}>
                        <div className="form-group mb-0">
                            <label
                                className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                                htmlFor="infoEmailID"
                            >
                                Primary Email Address
                            </label>
                            <input
                                {...register("primaryEmail", {
                                    pattern: {
                                        value:
                                            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Invalid email address",
                                    },
                                })}
                                type="text"
                                //   className="form-control form-control-lg required dh-input-box"
                                className={`form-control form-control-lg required dh-input-box ${errors.primaryEmail ? "dh-error-box" : ""
                                }`}
                                maxLength="100"
                                id="infoEmailID"
                                aria-label="infoEmailID"
                                aria-labelledby="infoEmailID"
                                aria-describedby="infoEmailID"
                                disabled="true"
                                // value={storeData?.primaryEmail}
                            />
                            {errors.public_email && (
                                <span className="dh-txt-danger">
                                    {errors.primaryEmail.message}
                                </span>
                            )}
                        </div>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col md={3} sm={3} xl={3} lg={3}>
                        <div className="form-group mb-0">
                            <label
                                className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                                htmlFor="vendorHipId"
                            >
                                HIP Organization ID
                            </label>
                            <input
                                {...register("vendorHipId")}
                                className={`form-control form-control-lg required dh-input-box ${errors.vendorHipId ? "dh-error-box" : ""
                                }`}
                                placeholder=""
                                disabled="true"
                                value={formValue?.vendorHipId}
                            />
                        </div>
                    </Col>
                    <Col md={3} sm={3} xl={3} lg={3}>
                        <div className="form-group mb-0">
                            <label
                                className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                                htmlFor="createdBy"
                            >
                                Registered By
                            </label>
                            <input
                                {...register("createdBy")}
                                className={`form-control form-control-lg required dh-input-box ${errors.createdBy ? "dh-error-box" : ""
                                }`}
                                placeholder=""
                                disabled="true"
                                value={formValue?.primaryEmail}
                            />

                        </div>
                    </Col>
                    <Col md={3} sm={3} xl={3} lg={3}>
                        <div className="form-group mb-0">
                            <label
                                className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                                htmlFor="createTs"
                            >
                                Registered Time
                            </label>
                            <input
                                {...register("createTs")}
                                className={`form-control form-control-lg required dh-input-box ${errors.createTs ? "dh-error-box" : ""
                                }`}
                                placeholder=""
                                disabled="true"
                                // value={formValue?.createTs ? formValue?.createTs : storeData?.createTs}
                                value={formValue?.createTs ? convertUTCDateToLocalDate(formValue?.createTs) : ""}
                            />

                        </div>
                    </Col>

                    <Col md={3} sm={3} xl={3} lg={3}>
                        <div className="form-group mb-0">
                            <label
                                className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                                htmlFor="lastUpdtTs"
                            >
                                Last Updated Time
                            </label>
                            <input
                                {...register("lastUpdtTs")}
                                className={`form-control form-control-lg required dh-input-box ${errors.lastUpdtTs ? "dh-error-box" : ""
                                }`}
                                // value={mobile}
                                disabled="true"
                                // value={storeData?.lastUpdtTs}
                                value={formValue?.lastUpdtTs ? convertUTCDateToLocalDate(formValue?.lastUpdtTs) : ""}
                            />
                            {errors.lastUpdtTs && (
                                <span className="dh-txt-danger">
                                    {errors.lastUpdtTs.message}
                                </span>
                            )}
                        </div>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col md={9} sm={9} xl={9} lg={9}>
                        <div className="form-group mb-0">
                            <label
                                className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                                htmlFor="additionalContactInfo"
                            >
                                Additional Contact Info
                            </label>
                            <textarea
                                {...register("additionalContactInfo", {
                                    maxLength: {
                                        value: "3000",
                                        message: "Maximum character count exceeded",
                                    },
                                })}
                                // ref={register}
                                className={`form-control form-control-lg  ${errors.primary_contact_info
                                    ? "dh-error-box"
                                    : ""
                                }`}
                                id="additionalContactInfo"
                                rows="3"
                                // name="additionalinfo"
                                aria-label="additionalContactInfo"
                                aria-labelledby="additionalContactInfo"
                                aria-describedby="additionalContactInfo"
                                disabled={mode === "view"}
                            ></textarea>
                            <span className="float-right text-muted mt-1 ">
                                <i
                                    className="fa fa-info-circle mr-1"
                                    aria-hidden="true"
                                ></i>
                                Max character : 3000
                            </span>
                            {errors.additionalContactInfo && (
                            <span className="dh-txt-danger">
                                {errors.additionalContactInfo.message}
                            </span>)}
                        </div>
                    </Col>
                    <Col md={3} sm={3} xl={3} lg={3}>
                        <div className="form-group mb-0">
                            <label
                                className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                                htmlFor="adminLastUpdtTs"
                            >
                               Admin Last Updated Time
                            </label>
                            <input
                                {...register("adminLastUpdtTs")}
                                className={`form-control form-control-lg required dh-input-box ${errors.adminLastUpdtTs ? "dh-error-box" : ""
                                }`}
                                // value={mobile}
                                disabled="true"
                                // value={storeData?.lastUpdtTs}
                                value={convertUTCDateToLocalDate(formValue?.adminLastUpdtTs)}
                            />
                        </div>
                        <br />
                    {/* </Col>
                    <Col md={3} sm={3} xl={3} lg={3}> */}
                        <div className="form-group mb-0">
                            <label
                                className="font-14 mb-2 dh-title-lb smallTitle text-brand"
                                htmlFor="lastUpdtUidNam"
                            >
                                Last Updated By
                            </label>
                            <input
                                {...register("lastUpdtUidNam")}
                                className={`form-control form-control-lg required dh-input-box ${errors.lastUpdtUidNam ? "dh-error-box" : ""
                                }`}
                                placeholder=""
                                disabled="true"
                                value={formValue?.lastUpdtUidNam}
                                // value={formValue?.primaryEmail}
                            />
                        </div>
                    </Col>
                </Row>
            </Form>
        </div>
    );

};
VendorDetailsTab.propTypes = {
    updateVendorDetails: PropTypes.func.isRequired,
    vendorInfo: PropTypes.string,
    updateUser: PropTypes.func.isRequired,
    userId: PropTypes.number,
    userAuth: PropTypes.object.isRequired,
    vendorDetails: PropTypes.object.isRequired,
    getVendorInfoList: PropTypes.func,
    user: PropTypes.object
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateVendorDetails: (user) => {
            dispatch({ type: UPDATE_VENDOR, value: user });
        },
        updateUser: (user) => {
            dispatch({ type: PUT_USER, value: user });
        },
        getVendorInfoList:(payload) => dispatch(getVendorInfoList(payload)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VendorDetailsTab);
